import React, { useState, useEffect } from 'react'
import { Progress } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChargingStation } from '@fortawesome/free-solid-svg-icons'
import "../output.css";

import MaterialCard from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const Card = ({ card, pressCb }) => {
  const [startTime, setStartTime] = useState((new Date()).getTime())
  const expirationTime = card.expiresAt ? card.expiresAt.getTime() : 0
  const totalTime = expirationTime - startTime // time user has to start charging
  //const [timeNow, setTimeNow] = useState(startTime)
  const [progress, setProgress] = useState(0)

  // Run only after initial render
  useEffect(
    () => {
      let interval;
      if (card.expiresAt) {
        const timeNow = (new Date()).getTime()
        const progress = ((timeNow - startTime) / totalTime) * 100
        setProgress(progress)
        if (progress <= 100) {
          interval = setTimeout(() => {
            const timeNow = (new Date()).getTime()
            const progress = ((timeNow - startTime) / totalTime) * 100
            console.log("progress: " + progress)
            setProgress(progress)
            //setTimeNow((new Date()).getTime())
          }, 50)
        }
      }
      return () => clearInterval(interval)
    },
    [progress, startTime]
  )

  //if (card.expiresAt && expirationTime >= timeNow) {
  return (
    //<Text>{card.provider_name} - {card.visual_number}</Text>
    /*
    <View className="table-row" onClick={onPress}>
      <Text>{card.provider_name} - {card.visual_number}</Text>
    </View>

    <CardHeader
            title={card.provider_name}
            titleTypographyProps={{ color: 'white' }}
            subheader={card.visual_number}
            subheaderTypographyProps={{ color: 'white' }} 
          />

    */
    <MaterialCard
      className="bg-gradient-to-r from-teal-500 to-teal-200 border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 rounded-lg lg:rounded-b-none lg:rounded-r pl-3 pr-2 py-4 flex flex-row justify-between leading-normal"
      onClick={() => { pressCb().then(() => setStartTime((new Date()).getTime())) }}
    >
      <CardActionArea>
        <Box sx={{ width: '100%' }} className="pt-1">
          <Grid container spacing={2}>
            <Grid item={true} xs={9.25} className="pl-3 pt-3">
              <Typography fontWeight='fontWeightMedium' variant="h5" component="div" className="text-white">
                {card.provider_name}
              </Typography>
              <Typography fontWeight='fontWeightRegular' variant="h6" component="div" className="text-white">
                {card.visual_number}
              </Typography>
            </Grid>
            <Grid item={true} xs={2.75} className="pt-2">
              <Box display="flex" justifyContent="flex-end">
                <FontAwesomeIcon icon={faChargingStation} size='4x' style={{ opacity: 0.2 }} className="pt-1 pb-2 pr-1" />
              </Box>
            </Grid>
            <Grid item={true} xs={12} className="pl-3 pr-2">
              {
                /*
                progress > 0 && progress <= 100
                  ? <LinearProgress
                      variant="determinate"
                      value={progress}
                      color='secondary'
                      className="mt-3" />
                  : <></>
                */
                
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{ opacity: (!card.expiresAt || card.expiresAt < (new Date()).getTime()) ? 0 : 1 }}
                  color='secondary'
                  className="mt-3" />
                
              }
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
      
    </MaterialCard>

    /*
    <div
      className="bg-gradient-to-r from-cyan-500 to-blue-500 px-6 border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 rounded-lg lg:rounded-b-none lg:rounded-r p-4 flex flex-row justify-between leading-normal"
      onClick={() => { setStartTime((new Date()).getTime()); pressCb() }}
    >
          <div className="m-0">
            <p className="text-3xl mb-0 text-white">{card.provider_name}</p>
            <p className="text-lg mb-0 text-white">{card.visual_number}</p>
            {
          
              //progress <=1
                //? <LinearProgress*/ /*value={progress}*/ /*color="orange" style={{ marginVertical: 10 }}></LinearProgress>
          //: <></>
              progress > 0 && progress <= 100
                ? <Progress
                    animated
                    className="my-3"
                    color="warning"
                    value={progress} />
                : <></>
            }
      </div>
      
        <FontAwesomeIcon icon={faChargingStation} size='5x' style={{ opacity: 0.1 }} />
      
    </div>
    */

    /*
    <div className="col-xl-6 col-lg-6" onClick={() => { setStartTime((new Date()).getTime()); pressCb() }}>
      <div className="card l-bg-green-dark">
        <div className="card-statistic-3 p-4">
          <div className="card-icon card-icon-large"><i className="fas fa-charging-station"></i></div>
          <div className="mb-4">
            <h2 className="card-title mb-0">{card.provider_name}</h2>
          </div>
          <div className="row align-items-center mb-2 d-flex">
            <div className="col-8">
              <h5 className="d-flex align-items-center mb-0">
                {card.visual_number}
              </h5>
            </div>
          </div>
          


        </div>
      </div>
    </div>
    */

    /*
    <div className="progress mt-1 " data-height="8" style={{ height: "8px;" }}>
            <div className="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: "25%;" }}></div>
          </div>
    */

    /*
    <div className="px-6 pt-5" onClick={() => { setStartTime((new Date()).getTime());  pressCb() }}>
      <LinearGradient
          // Background Linear Gradient
          colors={['#0a504a', '#38ef7d']}
          style={{
            flex: 1
          }}
          // Make it a horizontal gradient
        start={[0, 0]} end={[1, 0]}
        //style={rectangleStyle}
        className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 rounded-lg lg:rounded-b-none lg:rounded-r p-4 flex flex-row justify-between leading-normal"
      >
        <View
          //style={rectangleStyle}
          className="w-full"
        >
          <div className="mb-4 flex-col w-4/5">
            <p className="text-3xl mb-0 text-white">{card.provider_name}</p>
            <p className="text-lg mb-0 text-white">{card.visual_number}</p>
            {
              progress <=1
                ? <LinearProgress */ /*value={progress}*/ /*color="orange" style={{ marginVertical: 10 }}></LinearProgress>
    : <></>
}
</div>
<FontAwesome5 name='charging-station' className='h-full' size={60} style={{opacity: 0.1}}  />
</View>
</LinearGradient>
</div>
*/
  )
}