import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import { authorizeCard, startTx } from "../utils/authorizeCard"
import Loading from "../components/Loading"
import "../output.css"

import { Alert } from "reactstrap"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from "@mui/material"

// This page takes a card ID and authorizes it
// This is the page to which the QR code points
// User must be logged in and the card ID must be theirs

const Authorize = () => {
  const {
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0()

  const { card } = useParams()

  const [state, setState] = useState({
    error: null,
    authorized: undefined, // expiration date, or false (in case of failure), or undefined (in case of loading)
    //txStarted: undefined,
  })

  const authorizeCardAndStartTx = async (card) => {
    try {
      const token = await getAccessTokenSilently()
      
      //const idTag = card.tag_id
      const authorizationData = await authorizeCard(card, token)
      
      if (authorizationData.authorized) {
        const expiryDate = new Date(authorizationData.expiresAt)
        setState({
          error: null,
          authorized: expiryDate,
          //txStarted: undefined,
        })
      
        /*
        const res = await startTx(idTag)
        if (res.status === 'Failure') {
          // Show a message that the transaction was denied
          setState({
            error: JSON.stringify(res.reason),
            authorized: true,
            txStarted: false,
          })
        }
        else {
          setState({
            error: undefined,
            authorized: true,
            //txStarted: true,
          })
        }
        */
      }
      else {
        const reason = authorizationData.reason ?? 'Invalid card'
        setState({
          error: reason,
          authorized: false,
          //txStarted: undefined,
        })
      }
    } catch (error) {
      setState({
        error: error.message,
        authorized: false,
        //txStarted: undefined,
      })
    }
  }

  // TODO: need to fetch the card first from the API
  //       (only needed to be able to start the TX)
  const authCard = () => {
    if (card !== undefined) {
      const id = parseInt(card)
      if (isNaN(id)) {
        setState({
          error: 'Invalid card',
          authorized: false,
          txStarted: undefined,
        })
        return 
      }

      authorizeCardAndStartTx({ id })
    }
  }

  useEffect(authCard, [card])

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }

    authCard(card)
  }

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }

    authCard()
  }

  return (
    <div className="bg-light-gray flex h-screen justify-center items-center">
      <div class="text-center">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}

        {!card && (
          <p>No card specified</p>
        )}

        {card && state.authorized === undefined && (
          <>
            <p>Authorizing card...</p>
            <Loading />
          </>
        )}

        {card && !state.authorized && (
          <>
            <ErrorIcon sx={{ fontSize: 60 }} style={{ color: '#FF5F15' }} />
            <Typography>Could not authorize card: {state.error}</Typography>
          </>
        )}

        {
          card && state.authorized && (
            <>
              <CheckCircleIcon sx={{ fontSize: 60 }} style={{ color: '#4BB543' }} />
              <Typography>You can start charging until {state.authorized.toLocaleString()}</Typography>
            </>
          )
        }

        { /*
          // card is authorized, still waiting for session to start
          card && state.authorized && state.txStarted === undefined && (
            <>
              <p>Card is authorized, starting session...</p>
              <Loading />
            </>
          )
          */
        }

        {
          /*
          // session refused
          card &&
          state.authorized &&
          !state.txStarted && (
            <p>Session refused: {state.error}</p>
          )
          */
        }

        { /*
          // session accepted
          card &&
          state.authorized &&
          state.txStarted && (
            <p> Session started</p>
          )
          */
        }
      </div>
    </div>
  )
}

export default withAuthenticationRequired(Authorize, {
  onRedirecting: () => <Loading />,
})

const handle = (e, fn) => {
  e.preventDefault()
  fn()
}