import { endpoint } from "./authorizeCard"

export async function getActiveSessions(token) {
  const response = await fetch(`${endpoint}/user/sessions`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.ok) {
    const data = await response.json()
    data.forEach((session) => {
      session.startDate = new Date(session.startDate)
    })
    return {
      success: true,
      sessions: data,
    }
  }

  return {
    success: false,
    response,
  }
}

export async function getChargers(token) {
  const response = await fetch(`${endpoint}/user/chargers`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.ok) {
    const data = await response.json()
    return {
      success: true,
      chargers: data,
    }
  }

  return {
    success: false,
    response,
  }
}

export async function stopSession(token, internalChargepointId, txId) {
  const response = await fetch(`${endpoint}/charger/${internalChargepointId}/stop`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ txId }),
  })

  if (response.ok) {
    return {
      success: true,
    }
  }

  const data = await response.json()

  return {
    success: false,
    reason: data.reason,
  }
}

export async function setChargingMode(token, internalChargepointId, mode) {
  const response = await fetch(`${endpoint}/charger/${internalChargepointId}/mode`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mode
    }),
  })

  if (response.ok) {
    return {
      success: true,
    }
  }

  const data = await response.json()

  return {
    success: false,
    reason: data.reason,
  }
}
