import React, { useState, useEffect } from "react"

import List from '@mui/material/List'
import Divider from '@mui/material/Divider'

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading"

import Header from "../components/Header"
import NavBar from "../components/NavBar"
import ActivityComponent from "../components/Activity"

import "../output.css";

import { getActivity } from "../utils/authorizeCard"

/*
// Make some fake data to test the UI
// one deposit and one session
const fakeData = [
  {
    amount: 100,
    occured_at: new Date(),
  },
  {
    chargepoint_description: "Klix BV",
    total_energy_wh: 10_000,
    occured_at: new Date(),
    duration: '2 hours',
    price_per_kwh: 79,
    total_price_eurocents: 790
  },
  {
    amount: 5349,
    occured_at: new Date(),
  },
]

function getActivity(_token) {
  console.log("fake data")
  console.log(fakeData)
  return {
    success: true,
    activity: fakeData,
  }
}
*/

const Activity = () => {
  const {
    error: authError,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const [activity, setActivity] = useState([])

  useEffect(() => {
    fetchActivity()
  }, [])

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently()
    } catch (error) {
      console.log("Authentication error: " + error)
      console.log("Redirecting to home page")

      // Authentication problem, log the user out and go back to home page
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  if (authError !== undefined) {
    console.log("Authentication error: " + authError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const fetchActivity = async () => {
    const token = await getAccessToken()
    const res = await getActivity(token)

    if (res.success) {
      const { activity } = res // array of sessions and deposits
      setActivity(activity)
    }
    else {
      throw new Error('request rejected by the server: ' + JSON.stringify(res.response))
    }
  }

  return (
    <div className="bg-light-gray h-screen">    
      <Header title="Recent activity" />
      <List sx={{ width: '100%', bgcolor: 'background.paper', mt: 1 }}>
        {
          activity.map((item, idx, array) => (
            // add a divider between each activity item
            <React.Fragment>
              <ActivityComponent activity={item} />
              {idx < array.length - 1 && <Divider variant="middle" component="li" />}
            </React.Fragment>
            ))
        }
      </List>
      <NavBar tab='Activity' />
    </div>
  )
}

export default withAuthenticationRequired(Activity, {
  onRedirecting: () => <Loading />,
})
