import React, { useState, useEffect } from "react"

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Alert } from "reactstrap"

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { Card } from "../components/Card"
import Header from "../components/Header";
import Loading from "../components/Loading";

import "../output.css";

import { authorizeCard, getCards } from "../utils/authorizeCard"

// Autorize API runs on port 8444 (internally) - 2053 (externally)
// OCPP proxy runs on port 8445 (internally) - 2096 (externally)
// This website runs on port 8443 (internally) - 8443 (externally)

const Cards = () => {
  const {
    error: authError,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout,
  } = useAuth0()

  const [authenticationError, setAuthenticationError] = useState(undefined)

  const [state, setState] = useState({
    error: null,
    apiMessage: null, // null | { color: 'success' | 'warning', msg: string }
  })

  const [cards, setCards] = useState([])

  useEffect(() => {
    fetchCards()
  }, [])

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(/*{
      authorizationParams: {
        audience: 'https://autorize.eu/api/',
        scope: 'read:cards authorize:card email',
      }
      }*/
      )
    } catch (error) {
      setAuthenticationError(error)
      return undefined
    }
  }

  if (authError !== undefined || authenticationError !== undefined) {
    console.log("Authentication error: " + authError ?? authenticationError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }

    await fetchCards()
  }

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }

    await fetchCards()
  }

  const authorizeCardAndStartTx = async (card) => {
    try {
      const token = await getAccessToken()
      if (token === undefined) return // failed to fetch access token
      
      const cardId = card.id
      const authorizationData = await authorizeCard(card, token)
      
      if (authorizationData.authorized) {
        // Make the card green and show the expiration time
        const expiryDate = new Date(authorizationData.expiresAt)
        setCards(cards.map(card => card.id === cardId ? { ...card, expiresAt: expiryDate } : card))
  
        setState({
          ...state,
          apiMessage: { color: 'green', msg: `Card authorized, you can start charging until ${expiryDate}` }
        })
      }
      else {
        // Show a message that the card was not authorized
        setState({
          ...state,
          apiMessage: { color: 'warning', msg: "Card denied: " + authorizationData.reason }
        })
      }
    } catch (error) {
      setState({
        ...state,
        apiMessage: { color: 'warning', msg: 'Failed to authorize card: ' + error.message }
      })
    }
  }

  // TODO: turn this and authorizeCardAndStartTx into a hook
  //       such that it is a modular component that we can re-use
  //         it fetches the token, then fetches the cards and returns the cards
  const fetchCards = async () => {
    try {
      const token = await getAccessToken()
      if (token === undefined) return // failed to fetch access token
      const res = await getCards(token)

      if (res.success) {
        const { cards } = res
        setCards(cards.map((card) => {
          return { ...card, expiresAt: card.authorized_at ? new Date(new Date(card.authorized_at).getTime() + 1000 * 90) : undefined }
        }))
      }
      else {
        throw new Error('request rejected by the server: ' + JSON.stringify(res.response))
      }
    } catch (error) {
      console.log("error")
      console.log(error)
      /*setState({
        ...state,
        error: 'Failed to fetch cards: ' + error.error,
      })*/
    }
  }

  const handle = (e, fn) => {
    e.preventDefault()
    fn()
  }

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({
      ...state,
      apiMessage: undefined,
    })
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <div className="bg-light-gray h-screen">
      {state.error === "consent_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleConsent)}
          >
            consent to get access to users api
          </a>
        </Alert>
      )}

      {state.error === "login_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            log in again
          </a>
        </Alert>
      )}

      <Snackbar
        autoHideDuration={6000}
        open={!!state.apiMessage}
        onClose={handleClose}
        message={state.apiMessage?.msg}
        color={state.apiMessage?.color}
        action={action}
      />

      <Header />
      <p className="text-xl font-bold mt-3 mb-2 text-center">Tap a card to autorise</p>
      <ul className="space-y-3.5 mx-4">
        {
          cards.map((card) => (
            <li className="list-item">
              <Card card={card} pressCb={() => authorizeCardAndStartTx(card)}>
              </Card>
            </li>
          ))
        }
      </ul>
    </div>
  ) // TODO: no longer use a message to indicate that a card is authorized but instead show the expiration time on the card
}

//export default Cards;

export default withAuthenticationRequired(Cards, {
  onRedirecting: () => <Loading />,
})
