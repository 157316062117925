import { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import EvStationIcon from '@mui/icons-material/EvStation'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf'
//import BoltIcon from '@mui/icons-material/Bolt'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Switch from '@mui/material/Switch'
import Chip from '@mui/material/Chip'

import { SessionSkeleton as ChargerSkeleton } from './Sessions'

const ChargerCard = ({ charger, setChargingMode }) => {
  const [expanded, setExpanded] = useState(false)
  const [isGreenMode, setIsGreenMode] = useState(charger.isGreenCharging)
  const [chargingModeConfirmed, setChargingModeConfirmed] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleModeChange = async () => {
    const oldMode = isGreenMode
    setIsGreenMode(!oldMode)
    setChargingModeConfirmed(false)

    // TODO: would be good to put a timeout on the promise
    //       such that users don't need to wait e.g. 1 minute
    //       for the HTTP request to timeout

    const success = await setChargingMode(charger, oldMode ? 'TRADITIONAL' : 'GREEN')
    if (success) {
      setChargingModeConfirmed(true)
    }
    else {
      // revert the UI update
      // TODO: useOptimistic could help as we would not need to manually
      //  revert the UI update
      setIsGreenMode(oldMode)
      setChargingModeConfirmed(true)
    }
  }

  return (
    <Card sx={{ minWidth: 275, mb: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <EvStationIcon color="primary" sx={{ fontSize: 40 }} />
          <div>
            <Typography variant="h6" component="div">
              {charger.description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {charger.address}
            </Typography>
          </div>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <Chip 
            label={charger.isOnline ? "Online" : "Offline"}
            color={charger.isOnline ? "success" : "error"}
            size="small"
          />
          
          <Stack direction="row" alignItems="center">
            {isGreenMode ? (
              <EnergySavingsLeafIcon sx={{ color: 'green' }} />
            ) : (
              <ElectricalServicesIcon color="warning" />
            )}
            <Switch
              checked={isGreenMode}
              onChange={handleModeChange}
              size="small"
              disabled={!chargingModeConfirmed}
            />
            <Typography variant="body2">
              {isGreenMode ? "Green charging" : "Traditional charging"}
              {!chargingModeConfirmed && " requested"}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={handleExpandClick}
        >
          <IconButton
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: '0.3s'
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Chargepoint ID: {charger.chargepointId}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Number of Connectors: {charger.connectorCount}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Price per kWh: €{(charger.pricePerKWh / 100).toFixed(2)}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Max Amperage: {charger.maxAmperage}A
            </Typography>
            {charger.backendUrl && (
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Backend URL: {charger.backendUrl}
              </Typography>
            )}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  )
}

export const Chargers = ({ chargers, setChargingMode }) => {
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const content = !chargers || chargers.length === 0 ? (
    <Typography variant="body1" color="text.secondary">
      No chargers found
    </Typography>
  ) : (
    chargers.map((charger) => (
      <ChargerCard key={charger.chargepointId} charger={charger} setChargingMode={setChargingMode} />
    ))
  )

  return (
    <Box sx={{ mt: 2, width: '100%', maxWidth: '400px' }}>
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          My Chargers
        </Typography>
        <IconButton
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: '0.3s'
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" sx={{ mt: 1 }}>
        {
          !chargers ? (
            <ChargerSkeleton />
          ) :
            content
        }
      </Collapse>
    </Box>
  )
}
