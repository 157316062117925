import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"

import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import EvStationIcon from '@mui/icons-material/EvStation'
import { Button } from '@mui/base/Button'

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading"
import { getNearbyChargers } from "../utils/authorizeCard"

import Header from "../components/Header"
import NavBar from "../components/NavBar"

import "../output.css";

/*
const dummyChargers = [
  { 
    id: 1,
    description: `Klix BV`,
    address: `Speteveld 26, Herzele`,
    distance: 6.30 // meters
  },
  {
    id: 2,
    description: `Gabriels`,
    address: `Oudenaardsesteenweg 442, Erpe-Mere`,
    distance: 6200, // meters
    icon: `https://laadpastop10.nl/storage/charging-cards/SG6gmdDg9xfSDV0huawS8v5Eh1ERRtHBCTnazIHZ.png`
  },
]
*/

export const ChargerList = () => {
  const {
    error: authError,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const [searchTerm, setSearchTerm] = useState("")
  const [chargers, setChargers] = useState()

  // TODO: put the auth0 logic with getAccessToken and authError handling in a custom hook
  //       in order to simplify all the components that are doing this
  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently()
    } catch (error) {
      console.log("Authentication error: " + error)
      console.log("Redirecting to home page")

      // Authentication problem, log the user out and go back to home page
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  if (authError !== undefined) {
    console.log("Authentication error: " + authError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  useEffect(async () => {
    const token = await getAccessToken()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // Fetch nearby chargers
          const res = await getNearbyChargers(position, token)
          if (res.success) {
            setChargers(res.nearbyChargers)
          }
          else {
            // TODO: handle errors
          }
        },
        (error) => {
            // display an error if we cant get the users position
            console.error('Error getting user location:', error);
        }
      )
    }
    else {
      // TODO: display a message that geolocation is not working
    }
  }, [])

  const filteredChargers = useMemo(
    () => {
      return chargers?.filter(charger => 
        charger.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        charger.address.toLowerCase().includes(searchTerm.toLowerCase())
      )
    },
    [chargers, searchTerm]
  )

  const formatDistance = (meters) => {
    if (meters < 1000) {
      return `${meters.toFixed(0)}m`
    }
    return `${(meters/1000).toFixed(1)}km`
  }

  // TODO: fix the `Link` below, we should forward user differently (see how we do it in one of the other screens)
  return (
    <div className="bg-light-gray h-screen">    
      <Header title="Charge" />
      
      <div className="px-4 py-2">
        <input
          type="text"
          placeholder="Search chargers..."
          className="w-full p-2 rounded border border-gray-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {filteredChargers &&
        <List sx={{ width: '100%', bgcolor: 'background.paper', mt: 1 }}>
          {
            filteredChargers.map((charger, idx, array) => (
              <React.Fragment key={charger.id}>
                <Button href={`/budget/${charger.id}`} className="no-underline text-inherit">
                  <div className="flex px-4 py-3">
                    <div className="flex-shrink-0 mr-4 flex items-center">
                      <ChargerIconComponent url={charger.icon} />
                    </div>
                    <div>
                      <div className="font-medium">{charger.description}</div>
                      <div className="text-sm text-gray-600">{charger.address}</div>
                      <div className="text-sm text-gray-500 mt-1">
                        {formatDistance(charger.distance)} away
                      </div>
                    </div>
                  </div>
                </Button>
                {idx < array.length - 1 && <Divider variant="middle" component="li" />}
              </React.Fragment>
            ))}
        </List>
      }

      {
        !filteredChargers && <Loading />
      }

      <NavBar tab='Charge' />
    </div>
  )
}

const ChargerIconComponent = ({ url }) => {
  const [iconFound, setIconFound] = useState(true)

  if (url && iconFound) {
    return (
      <img 
        src={url}
        style={{ width: 48, height: 48 }}
        className="object-contain"
        onError={(_e) => {
          setIconFound(false)
        }}
      />
    )
  }

  return (
    <EvStationIcon sx={{ fontSize: 48, color: 'primary.main' }} />
  )
}

export default withAuthenticationRequired(ChargerList, {
  onRedirecting: () => <Loading />,
})
