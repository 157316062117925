import React, { useState, useEffect } from "react"
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput'
import { checkout } from "../utils/authorizeCard"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from "../components/Loading"
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { unstable_useNumberInput as useNumberInput } from '@mui/base/unstable_useNumberInput'


import Header from "../components/Header"
import NavBar from "../components/NavBar"

import { getBalance } from "../utils/authorizeCard"

const Account = () => {
  const {
    error: authError,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently()
    } catch (error) {
      console.log("Authentication error: " + error)
      console.log("Redirecting to home page")

      // Authentication problem, log the user out and go back to home page
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  if (authError !== undefined) {
    console.log("Authentication error: " + authError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const [message, setMessage] = useState("")
  const [amount, setAmount] = useState(20)
  const [balance, setBalance] = useState()

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const amountStr = query.get("amount")
      const amount = parseInt(amountStr)

      if (isNaN(amount)) {
        setMessage("Successfully topped up your balance! The money will soon be available on your account.");
      } else {
        setMessage(`Successfully topped up your balance with ${amount/100}€! The money will soon be available on your account.`);
      }
    }

    if (query.get("canceled")) {
      setMessage(
        "Top up cancelled. Please try again."
      );
    }
  }, []);

  const fetchSaldo = async () => {
    const token = await getAccessToken()
    const res = await getBalance(token)

    if (res.success) {
      setBalance(res.balance)
    }
  }

  useEffect(fetchSaldo, [])

  const initiateCheckout = async () => {
    const token = await getAccessToken()
    await checkout(amount, token)
  }

  return (
    <div className="bg-light-gray min-h-screen">
      <Header title="Account" />
      <div className="flex flex-col items-center px-4 py-6">
        {balance !== undefined && (
          <Box sx={{ 
            backgroundColor: 'white', 
            borderRadius: '8px', 
            padding: '20px', 
            marginBottom: '24px',
            width: '100%',
            maxWidth: '400px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              Available Balance
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', color: 'primary.main', mt: 2 }}>
              {format(balance)}€
            </Typography>
          </Box>
        )}
        {message && <Message message={message} />}
        <Box sx={{ 
          backgroundColor: 'white', 
          borderRadius: '8px', 
          padding: '20px', 
          width: '100%',
          maxWidth: '400px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <Typography variant="h6" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
            Top up your account
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            {[20, 50, 100].map((preset) => (
              <Button
                key={preset}
                variant="outlined"
                sx={{ width: '30%' }}
                onClick={() => {
                  setAmount(preset);
                  initiateCheckout();
                }}
              >
                {preset}€
              </Button>
            ))}
          </Box>
          <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
            Or enter a custom amount:
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CustomNumberInput
              min={1}
              value={amount}
              onChange={(_event, amount) => setAmount(amount)}
            />
            <Button onClick={initiateCheckout} variant="contained" size="large" sx={{ width: '22%' }}>
              Pay
            </Button>
          </Box>
        </Box>
      </div>
      <NavBar tab='Account' />
    </div>
  )
}

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
)

const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  const { 
    getRootProps, 
    getInputProps, 
    getIncrementButtonProps, 
    getDecrementButtonProps 
  } = useNumberInput(props);

  return (
    <div {...getRootProps()} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: '8px', height: '100%' }}>
        <Button {...getIncrementButtonProps()} variant="outlined" color="primary" style={{ height: '50%' }}>
          ▴
        </Button>
        <Button {...getDecrementButtonProps()} variant="outlined" color="primary" style={{ height: '50%' }}>
          ▾
        </Button>
      </div>
      <input {...getInputProps()} ref={ref} style={{ 
        width: '75px', 
        textAlign: 'center', 
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        marginRight: '8px'
      }} />
    </div>
  );
});
// TODO: add border color to input which is the primary color
//       also make the arrows smaller such that they align with the height of the input

/*
const NumberIn = ({ value, onChange, sx }) => {
  return (
    <NumberInput
      min={1}
      value={value}
      onChange={(event, amount) => onChange(event, amount)}
      sx={sx}
    />
  )
}
*/

export default withAuthenticationRequired(Account, {
  onRedirecting: () => <Loading />,
})

// TODO: move this to an util file (see duplicate function in Activity.js component)
function format(amount, decimals = 2) {
  /* 
     Taken from https://stackoverflow.com/a/14428340/2828147
       1        --> "1.00"
       12       --> "12.00"
       123      --> "123.00"
       1234     --> "1,234.00"
       12345    --> "12,345.00"
       123456   --> "123,456.00"
       1234567  --> "1,234,567.00"
       12345.67 --> "12,345.67"
  */
  return amount.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}